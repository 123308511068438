<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="40"
        />
        TastyTomatoes
      </div>

      <v-spacer></v-spacer>

      <v-btn text @click="showImpress">
        <span class="mr-2">Impressum</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-parallax :src="require('@/assets/hero2.jpg')">
        <v-row align="center" justify="center">
          <v-col class="text-center" cols="5">
            <v-img
              :src="require('@/assets/logo.png')"
              width="200"
              position="center"
              style="margin: 0 auto"
            >
            </v-img>
            <h1 class="text-h4 font-weight-thin mb-4">LUST AUF TOMATEN?</h1>
          </v-col>
        </v-row>
      </v-parallax>

      <v-container>
        <v-row>
          <v-col cols="12" md="3" order="2" order-md="1">
            <v-row>
              <v-col cols="10" md="12" class="offset-1 offset-md-0">
                <v-card class="mx-auto">
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-img
                    height="250"
                    :src="require('@/assets/side1.jpg')"
                  ></v-img>

                  <v-card-title>Warum selber Tomaten anpflanzen?</v-card-title>

                  <v-card-text>
                    Gekaufte Tomaten sind fade, rot und langweilig. Wie toll
                    wäre es doch nur, im Sommer einen frischen, bunten,
                    aussergewöhnlich feinen Tomatensalat aufzutischen? Wenn du
                    genau das tun möchtest, bist du hier am richtigen Ort. Ich
                    ziehe jedes Jahr über hundert Setzlinge verschiedener
                    Tomatensorten an, um jedem die Freude an der Vielfältigkeit
                    unserer Nutzpflanzen weiterzugeben
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="10" md="12" class="offset-1 offset-md-0">
                <v-card class="mx-auto">
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-img
                    height="250"
                    :src="require('@/assets/side2.jpg')"
                  ></v-img>

                  <v-card-title
                    >Welche Bedingungen sind erforderlich?</v-card-title
                  >

                  <v-card-text
                    >Tomatenpflanzen müssen nicht unbedingt in einem Beet
                    gehalten werden. Sie eignen sich sehr gut als Topfpflanzen
                    und können ohne weiteres auf dem Balkon gedeihen. Bevorzugt
                    werden sonnige Standorte mit Regenschutz. Bei zu viel Regen
                    und Nässe fangen die wertvollen Früchte an zu
                    faulen.</v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="10" md="12" class="offset-1 offset-md-0">
                <v-card class="mx-auto">
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-img
                    height="250"
                    :src="require('@/assets/side3.jpg')"
                  ></v-img>

                  <v-card-title>Fragen & Antworten</v-card-title>

                  <v-card-text>
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(item, i) in faq"
                        :key="'faq-' + i"
                      >
                        <v-expansion-panel-header>
                          {{ item.question }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          {{ item.answer }}
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="9" order="1" order-md="2">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title> Verfügbare Sorten </v-card-title>
                  <v-card-text>
                    <v-container>
                      <masonry
                        :gutter="{ default: '30px', 700: '15px' }"
                        :cols="{ default: 5, 1000: 3, 700: 2, 500: 1 }"
                      >
                        <v-card
                          v-for="item in items"
                          :key="'available-sorts-' + item.id"
                          class="mt-2 mb-2 pa-3 text-center justify-center"
                          color="purple lighten-4"
                          flat
                        >
                          <v-img
                            :src="item.img"
                            style="width: 30%"
                            class="justify-center mx-auto"
                          ></v-img>
                          <div class="text-h6">{{ item.title }}</div>
                        </v-card>
                      </masonry>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title> Setzlinge bestellen! </v-card-title>
                  <v-card-text>
                    <v-alert type="success" prominent v-if="done && !error">
                      <div class="text-h6">Tomaten ahoi!</div>

                      <p>Danke für deine Bestellung.</p>
                      <p>
                        Ich werde mich zeitnah mit dir in Verbindung setzen um
                        den Übergabezeitpunkt festzulegen. <br />
                        Solltest du währenddessen Fragen haben, schreib mir eine
                        Mail an info [ät] tasty-tomatoes [punkt] ch.
                      </p>

                      <p>Viele Grüsse aus Amden, <br />Zoe</p>
                    </v-alert>
                    <v-alert
                      type="warning"
                      prominent
                      v-if="done && serverError"
                    >
                      <div class="text-h6">Technische Schwierigkeiten</div>
                      <p>
                        Du hast alles richtig gemacht, es ist aber ein
                        Serverfehler aufgetreten und deine Bestellung konnte
                        nicht abgesendet werden
                      </p>
                      <p>
                        Bitte sende mir ein Mail mit deiner Bestellung an info
                        [ät] tasty-tomatoes [punkt] ch
                      </p>
                      <p>Danke!</p>
                    </v-alert>
                    <v-alert type="warning" prominent v-if="done && error">
                      <div class="text-h6">Ein Satz mit X...</div>
                      <p>... das war wohl nix.</p>
                      <p>
                        Leider konnte deine Bestellung nicht erfasst werden.
                      </p>
                      <p>
                        Bitte sende mir direkt eine Mail an info [ät]
                        tasty-tomatoes [punkt] ch
                      </p>
                      <p>Danke!</p>
                    </v-alert>
                    <v-stepper v-if="!done" v-model="e1" vertical disabled="">
                      <v-stepper-step :complete="e1 > 1" step="1">
                        Setzlinge auswählen
                      </v-stepper-step>

                      <v-stepper-content step="1">
                        <v-alert type="info" outlined>
                          Sollten gewisse Setzlinge nicht (mehr) verfügbar sein,
                          informiere ich dich darüber direkt.
                        </v-alert>
                        <v-container>
                          <v-form>
                            <v-row>
                              <v-col
                                v-for="(item, i) in items"
                                :key="'order-' + i"
                                cols="6"
                                md="2"
                              >
                                <v-text-field
                                  v-model="form.products[item.title]"
                                  aria-valuemin="0"
                                  :label="item.title"
                                  persistent-hint
                                  type="number"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>

                        <v-btn
                          color="primary"
                          @click="e1 = 2"
                          :disabled="hasZeroAmount"
                        >
                          Weiter
                        </v-btn>
                      </v-stepper-content>

                      <v-stepper-step :complete="e1 > 2" step="2">
                        Deine Kontaktdaten
                      </v-stepper-step>

                      <v-stepper-content step="2">
                        <v-form>
                          <v-text-field
                            v-model="form.name"
                            label="Name"
                            required
                            placeholder="Max Tomate"
                            persistent-placeholder
                          ></v-text-field>
                          <v-text-field
                            v-model="form.phone"
                            label="Tel-Nr."
                            placeholder="079 203 92 02"
                            persistent-placeholder
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="form.email"
                            label="E-Mail"
                            persistent-placeholder
                            placeholder="max.tomate@gartenhaus.ch"
                            required
                            type="email"
                          ></v-text-field>
                        </v-form>

                        <v-btn
                          color="primary"
                          @click="e1 = 3"
                          :disabled="!form.name || !form.phone || !form.email"
                        >
                          Weiter
                        </v-btn>

                        <v-btn text @click="e1 = 1"> Zurück </v-btn>
                      </v-stepper-content>

                      <v-stepper-step step="3">
                        Zusammenfassung
                      </v-stepper-step>

                      <v-stepper-content step="3">
                        <div class="text-h6">Angaben über dich</div>
                        <p>
                          {{ form.name }}, {{ form.email }}, {{ form.phone }}
                        </p>

                        <div class="text-h6">Deine Bestellung</div>

                        <p>
                          <span
                            :key="'summary-' + product.name"
                            v-for="product in productsFmt"
                            ><b>{{ product.amount }}</b
                            >x {{ product.name }} <br
                          /></span>
                        </p>

                        <div v-if="loading">
                          <v-progress-circular
                            :size="100"
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                        </div>

                        <p>
                          Mit dem Klicken auf Absenden stimmst du zu, das
                          Tasty-Tomatoes deine Daten, die du oben angegeben
                          hast, für die Bestellabwicklung verwenden darf. <br />
                          <span class="text--secondary"
                            >Beim Absenden werden zum Schutz vor automatisierten
                            Abfragen Daten an Google reCAPTCHA übertragen.
                            <br />
                            Die
                            <a
                              target="_blank"
                              href="https://policies.google.com/privacy"
                              >Datenschutzbedingungen</a
                            >
                            und
                            <a
                              target="_blank"
                              href="https://policies.google.com/terms"
                              >Nutzungsbedingungen</a
                            >
                            von Google kommen zur Anwendung.</span
                          >
                        </p>
                        <div>
                          <v-btn
                            color="primary"
                            @click="submit"
                            :disabled="loading"
                          >
                            Absenden
                          </v-btn>

                          <v-btn text @click="e1 = 2" :disabled="loading">
                            Zurück
                          </v-btn>
                        </div>
                      </v-stepper-content>
                    </v-stepper>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6"> </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <ImpressModal></ImpressModal>
    </v-main>
  </v-app>
</template>

<script>
import ImpressModal from "./components/ImpressModal";
import { EventBus } from "./event-bus";
import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue from "vue";
const axios = require("axios");
Vue.use(VueReCaptcha, {
  siteKey: "6Lc5d5YeAAAAAHUqYpKaxyIuz7sva_YTLa13yzEj",
  loaderOptions: {
    autoHideBadge: true,
  },
});

export default {
  name: "App",

  components: {
    ImpressModal,
  },

  computed: {
    productsFmt() {
      let clean = [];
      for (const type in this.form.products) {
        if (this.form.products[type] && this.form.products[type] > 0)
          clean.push({ amount: this.form.products[type], name: type });
      }
      return clean;
    },
    hasZeroAmount() {
      return (
        this.productsFmt.map((a) => a.amount).reduce((a, v) => a + v, 0) === 0
      );
    },
  },
  data: () => ({
    e1: 1,
    done: false,
    error: false,
    serverError: false,
    loading: false,
    form: {
      products: {},
    },
    faq: [
      {
        question: "Was kosten deine Setzlinge?",
        answer:
          "Grundsätzlich nichts, ich liebe es, sie zu verschenken. Wenn du mich aber unterstützen\n" +
          "möchtest, kannst du dies gerne tun. Tomatensetzlinge kann man normalerweise für 2\n" +
          "- 4 Franken kaufen. Ich freue mich aber auch über andere Pflanzen als Tausch",
      },
      {
        question: "Wann und wo hole ich die Setzlinge ab?",
        answer:
          "Die Setzlinge kannst du von April bis Mai bei mir in Amden (SG) oder bei einem spezifisch abgemachten Treffpunkt abholen. Dies wird noch persönlich kommuniziert. ",
      },
      {
        question: "Wie sieht das mit dem Umtopfmaterial aus?",
        answer:
          "Bitte alles Material nach dem Umtopfen zurückbringen! Somit kann ich alles wieder im nächsten Jahr für die neuen Setzlinge wiederverwenden. So spare ich nicht nur Kosten und Aufwand, sondern wir vermeiden auch unnötigen Abfall und schonen unsere Umwelt. ",
      },
      {
        question: "Ich habe selber Umtopfmaterialien. Kann ich die abgeben?",
        answer:
          "Falls du noch Töpfe und kleine, dünne Stangen in deiner Scheune, Garage oder im Keller ohne Verwendung herumliegen hast, kannst du diese mir mitgeben. Darüber freue ich mich immer sehr.",
      },
    ],
    items: [
      {
        id: 1,
        title: "Auriga",
        img: require("@/assets/tomatoes/auriga.png"),
      },
      {
        id: 14,
        title: "Basilikum-Mix",
        img: require("@/assets/tomatoes/basil.png"),
      },
      {
        id: 3,
        title: "Birnenförmige Gelbe",
        img: require("@/assets/tomatoes/birnenfoermig_gelb.png"),
      },
      {
        id: 4,
        title: "Black Cherry",
        img: require("@/assets/tomatoes/black_cherry.png"),
      },

      {
        id: 5,
        title: "Black Zebra",
        img: require("@/assets/tomatoes/blaue_zebra.png"),
      },
      {
        id: 2,
        title: "Dancing with smurfs",
        img: require("@/assets/tomatoes/dancing_with_smurfs.png"),
      },
      {
        id: 6,
        title: "Gelbe von Thun",
        img: require("@/assets/tomatoes/gelbe_von_thun.png"),
      },
      {
        id: 7,
        title: "Green Grape",
        img: require("@/assets/tomatoes/green_cherry.png"),
      },
      {
        id: 8,
        title: "Green Zebra",
        img: require("@/assets/tomatoes/green_zebra.png"),
      },
      {
        id: 9,
        title: "Milchperle",
        img: require("@/assets/tomatoes/milchperle.png"),
      },
      {
        id: 10,
        title: "Red Cherry",
        img: require("@/assets/tomatoes/red_cherry.png"),
      },
      {
        id: 11,
        title: "Rot Gelb Gestreifte Runde",
        img: require("@/assets/tomatoes/rggs.png"),
      },
      {
        id: 12,
        title: "Rouge de Marmande",
        img: require("@/assets/tomatoes/rouge_de_marmande.png"),
      },
      {
        id: 13,
        title: "Zürcher Originale",
        img: require("@/assets/tomatoes/zuercher_originale.png"),
      },
    ],
  }),
  methods: {
    async submit() {
      await this.$recaptchaLoaded();
      this.loading = true;
      this.form.products = this.productsFmt;

      const token = await this.$recaptcha("login");

      this.form.token = token;

      axios
        .post("https://api.tasty-tomatoes.ch", this.form)
        .catch((e) => {
          const statusCode =
            e.response && e.response.status ? e.response.status : 500;
          if (statusCode === 429) this.error = true;
          else this.serverError = true;
        })
        .then(() => {
          this.done = true;
        });
    },
    showImpress() {
      EventBus.$emit("show-impress");
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Impressum
        </v-card-title>

        <v-card-text class="text--black">
          <p class="subtitle-1">
            TastyTomatoes ist ein Angebot von
            Zoe Kok <br>
            Kontakt: info [at] tasty-tomatoes [dot] ch
          </p>

          <p class="overline">
          site created by
            <a href="https://dario.icu">dario.icu</a></p>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {EventBus} from "../event-bus";

export default {
  name: "ImpressModal",
  data() {
    return {
      dialog: false,
    }
  },
  mounted() {
    EventBus.$on("show-impress", () => this.dialog = true)
  }
}
</script>

<style scoped>

</style>